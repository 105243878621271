import React from 'react'
import Logo from './logo'
import { Drawer, Menu } from 'antd'
import { BsDownload } from "react-icons/bs"
import { TbSmartHome } from "react-icons/tb"
import { MdOutlineDiamond, MdOutlineDisplaySettings } from "react-icons/md"
import { LuSettings, LuStickyNote } from "react-icons/lu"
import { BiPyramid, BiStar, BiUser } from 'react-icons/bi'
import { PiMoneyBold, PiMusicNotes } from "react-icons/pi"
import { useLocation, useNavigate } from 'react-router-dom'
import { GoMegaphone } from 'react-icons/go'
import { useRecoilValue, useResetRecoilState } from 'recoil'
import { pageTitleAtom } from '../atoms/others/others.atom'
import authAtom from '../atoms/auth/auth.atom'
// import { FaChevronLeft } from 'react-icons/fa'
// import { isEqual } from '../context/utils'
// import { routes } from '../router'

function Sidebar({
  isOpen,
  onClose,
}) {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const setPageTitle = useResetRecoilState(pageTitleAtom)
  const { backPath, backBtn, title } = useRecoilValue(pageTitleAtom)
  const {permissions} = useRecoilValue(authAtom)

  const items = [
    {
      key: "/",
      label: "Dashboard",
      icon: <TbSmartHome className='!text-base' />,
      permission: "CanViewDashboard"
    },
    {
      key: "/revenue",
      label: "Revenue",
      icon: <PiMoneyBold className='!text-base' /> ,
      permission: "CanViewEarnings"
    },
    {
      key: "/users",
      label: "Users",
      icon: <BiUser className='!text-base' /> ,
      permission: "CanViewUsers"
    },
    {
      key: "/celebrities",
      label: "Celebrities",
      icon: <BiStar className='!text-base' /> ,
      permission: "CanViewCelebrities"
    },
    {
      key: "/promo-teams",
      label: "Promo Teams",
      icon: <BiPyramid className='!text-base' /> ,
      permission: "CanViewPromoTeam"
    },
    {
      key: "/shoutouts",
      label: "Shoutouts",
      icon: <GoMegaphone className='!text-base' /> ,
      permission: "CanViewShoutOut"
    },
    {
      key: "/song-promos",
      label: "Song Promos",
      icon: <PiMusicNotes className='!text-base' /> ,
      permission: "CanViewSongPromotions"
    },
    {
      key: "/reports",
      label: "Reports",
      icon: <LuStickyNote className='!text-base' /> ,
      permission: "CanViewReport"
    },
    {
      key: "/package-manager",
      label: "Package Manager",
      icon: <MdOutlineDiamond className='!text-base' /> ,
      permission: "CanViewSongPromotionPackage"
    },
    {
      key: "/withdrawals",
      label: "Withdrawals",
      icon: <BsDownload className='!text-base' /> ,
      permission: "CanViewWithdrawals"
    },
    {
      key: "/transactions",
      label: "Transactions",
      icon: <MdOutlineDisplaySettings className='!text-base' /> ,
      permission: "CanViewTransactions"
    },
    {
      key: "/settings",
      label: "Settings",
      icon: <LuSettings className='!text-base' /> ,
      permission: "CanViewRole"
    },
  ];

  
  const handleNavigate = ({ key }) => navigate(key)
  // const goBack = () => {navigate(backPath || pathname || -1); setPageTitle()}
  // const pageTitle = title || routes?.find(({ path }) => isEqual(path, pathname))?.label
  const Content = () => (
  <div className='w-full h-full overflow-y-auto py-10 lg:px-2 space-y-7'>
    {/* <div className='flex items-center gap-2 text-white' hidden={!backBtn}>
      <Button onClick={goBack} className='!bg-transparent !text-white' type='text' size='large' hidden={!backBtn} icon={<FaChevronLeft />} />
      <p className='text-2xl font-bold capitalize' hidden={!backBtn}>{pageTitle || "Not Found"}</p>
    </div> */}
    <Logo />
    <Menu
      style={{ border: 0, color: "#fff", fontSize: "16px", fontWeight: "500", background: "transparent" }}
      defaultSelectedKeys={[pathname]}
      defaultOpenKeys={[pathname]}
      onSelect={handleNavigate}
      className='!space-y-2'
      mode="inline"
      items={items.filter((item) => 
        permissions.find((perm) => perm.name === item.permission)
      )}
      theme='dark'
    />
  </div>)
  return (
    <div className='w-0 md:w-[20%] lg:w-[15%] z-30 bg-[#0D0F10]'>
      <Content />
      <Drawer
        width={270}
        className='!bg-[#0D0F10]'
        placement="left"
        onClose={onClose}
        closable={false}
        open={isOpen}
      >
        <Content />
      </Drawer>
    </div>
  )
}

export default Sidebar